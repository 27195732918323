import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'reactstrap'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

const pageMeta = {
  title: '404: Not found',
  type: '404',
  description: 'Lou Groshek 404 Not Found Page',
  keywords: `lou, groshek, lou groshek, 404`,
}

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} pageType={pageMeta.type}>
      <Row className="heading">
        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 3 }}>
          <h1>And things were going so good... </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 3 }}>
          <p>
            You just hit a route that doesn&#39;t exist. Sorry about that. If
            you like, you can let us know so we can fix it!
          </p>
          <Link to="/contact/" className="btn btn-primary">
            CONTACT LOU
          </Link>
        </Col>
      </Row>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <SEO meta={{ ...pageMeta }} />
